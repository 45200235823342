.App {
  font-family: sans-serif;
  text-align: center;
}

.react-responsive-modal-container .react-responsive-modal-modal{
  background: #fff0!important;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 0%)!important;
  margin-top: 20%!important;
  color: #e80f0f;
  font-size: 18px;
  font-weight: bolder;
  margin-right: 17%;
}
.react-responsive-modal-closeButton{
  display: none!important;
}
.react-responsive-modal-overlay {
  background: #ddddddbf!important;}
  button {
    background: none;
    border: 1px solid #00217E;
    font-size: 20px;
    color: #fff;
    z-index: 10000000000000;
    position: relative;
    border-radius: 0px;
  }
#spin {
  width: 140px;
  height: 45px;
  background-image: linear-gradient(to right, #00217E 0%, #00217E 100%);
  z-index: 10000000000000;
  position: relative;
}
#reset {
  width: 150px;
  height: 45px;
  background-image: linear-gradient(to right, #00217E 0%, #00217E 100%);
}

#spin:focus,
#reset:focus {
  outline: none;
}

.display{
margin-top: 20px;
}
.readout {
margin-top: 0;
margin-bottom: 0;
}

#selector {
  position: absolute;
  top: 12%;
  right: 44%;
  font-size: 40px;
  z-index: 3;
  height: 50px;
  width: 50px;
  /* transform: rotate(90deg); */
  border-radius: 50%;
}

input {
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
}
.wheel-wrapper {
  width: 100%;
}
